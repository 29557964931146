.employee{
  margin-top: 0%;

}

.emp-container{
    display: flex;
    flex-direction: row;
    padding: 2%
}

.emp-title{
    background-color: rgba(51, 67, 80, 1);
    font-size: 400%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 5%;
    color: rgb(221, 231, 245);
    font-weight: 450;
    
}

.emp-content{
    background-color:rgb(134, 155, 183); 
    font-size: 190%;
    font-weight: 350;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}





@media screen and (max-width: 800px) {
 .emp-container{
 
    flex-direction: column;
   
}

.emp-title{
    border-bottom-right-radius: 0px;
     border-bottom-left-radius: 0px;
  
    
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    
}

.emp-content{
    
   
    border-top-right-radius: 0px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

}
