.Register {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-image: url('../asset/images/backreg.jpg');
  background-size: cover;
  background-position: center;
  align-items: center;
}

.form-group {
  display: flex;
  padding: 1%;
  height: fit-content;
  flex-direction: row;
  max-width: 80%; /* Changed width to max-width */
  border-radius: 30px;
  height: fit-content;
  align-items: center;
  background-color: rgb(230, 237, 246);
}

.reg-title {
  font-size: 150%;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1%;
  height: 90%;
  padding: 1%;
}

.input {
  width: 95%;
  outline: none;
  border: none;
  border-radius: 12px;
  margin-top: 2%;
  background-color: rgb(255, 255, 255);
}

.feild {
  margin-top: 3%;
}

.feild label {
  font-size: 100%;
}

.form-container {
 flex-wrap: wrap;
  flex: 1;
  height: 100%;
  overflow-y: auto; /* Added overflow-y to enable vertical scrolling if needed */
}

.image-div {
  width: 50%;
}

.google{
    width: 100%;
    object-fit:fill;
    cursor: pointer;

}

.googlebtn{
    background-color: rgba(240, 248, 255, 0);
    width: 70px;
    color: black;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.image-div img {
  width: 100%;
}

.gsign{
    margin-top: 10%;
}
@media screen and (max-width: 900px) {
  .image-div {
    display: none;
  }

  .form-group {
    flex-direction: column; /* Change flex direction to column for smaller screens */
    max-width: 100%; /* Adjusted max-width for smaller screens */
  }
}
