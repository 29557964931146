.Enq {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(51, 67, 80, 1);
  color: white;
  padding: 3%;
  overflow: hidden;
  margin-top: 3%;
}

.form-container {
  width: 50%; /* Adjust as needed */
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input, textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
.Enq-title{
   font-size: 190%;
   color: white;
}

/* Media query for mobile responsiveness */
@media screen and (max-width: 600px) {
  .form-container {
    width: 80%; /* Adjust as needed */
  }
}
