
.jobs{
    position: relative;
    height:300vh;
}

.jobs-container{
    position: sticky;
    top: 3%;
    display: flex;
    align-items: center;
    overflow: hidden;
    height:90vh;
    width: 100%;
    background-color: rgba(0, 0, 255, 0);
    
}

.card-cont{
   padding: 0%;
    height: 100%;
    width: 100vw;
}

.jobs-card{
    height: 100%;
    width: 100%;
    border-radius: 30px ;
    overflow: scroll;
    display: flex;
    color:rgb(214, 235, 247);
    
}
.jobs-content{
    margin: 5%;
    border-radius: 30px;
    font-size: x-large;
}

.motiondiv{
    display: flex;
    height: 100%;
    gap: 1%;
}


.job-card-title{
    font-size: 200%;
    text-align: center;
    color: rgb(193, 220, 236);;
}

.job-card-content{
    text-align: center;
   font-size: 100%;
   color: white;
}

.jobs-card:hover {
    filter:brightness(80%);
    
}
.jobs-card:hover {
    filter: brightness(80%);
    transform: scale(1.02); /* Zoom in by 10% */
    transition: transform 0.3s ease; /* Smooth transition effect */
}


@media screen and (max-width: 800px) {
 .jobs-card{
    width: 80vw;
    margin-left: 0%;
    gap:2%;
    height: 90%;
}
.jobs-container{
   
    top:10%
}

.job-card-title{
    font-size: 130%;
}

.job-card-content{
   font-size: 70%;
}
.card-cont{
   padding: 0%;
    height: 90%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

}

