.deep-clean {
    margin-top: 10%;  
    width: 100%;
    height: auto; /* Change height to auto */
    
    position: relative; /* Add position: relative */
}

.deep-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    
}

.image2 {
    object-fit: cover;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%; /* Change height to 100% */
    width: 100%;
    filter: brightness(30%);
}

.content {
    font-size: larger;
    color: rgb(214, 235, 247);
    z-index: 11;
    width: 90%;
    font-size: 150%;
    margin: 4%;
    text-align: center;
    position: relative; /* Add position: relative */
}

.content h1{
    font-size: 300%;
}



@media screen and (max-width: 900px) {

    
 .content {
    font-size: 130%;
 }
 .content h1{
    font-size: 200%;
 }
}
