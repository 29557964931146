/* NavMobile.css */

.nav-mobile {
  @apply lg:hidden;
  
}

.menu {
  
  @apply fixed left-0 shadow-4xl right-0 top-[3.5rem] p-5 pt-0 bg-neutral-950 border-b border-b-white/20;
}

.popup{
    background-color: rgb(79, 83, 87);
    border-radius: 8px;
    padding: 3%;
    width: fit-content;

}

.menu-list {
  @apply grid gap-2;
}

.menu-item {
  @apply w-full p-[0.08rem] rounded-xl bg-gradient-to-tr from-neutral-800 via-neutral-950 to-neutral-700;
}

.menu-link {
  @apply flex items-center justify-between w-full p-5 rounded-xl bg-neutral-950;
}

.menu-text {
  @apply flex gap-1 text-lg;
}

.menu-icon {
  @apply text-xl;
}
