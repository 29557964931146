.Semi {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  height: fit-content;
  text-align: center;
  margin: 5%;
  color: rgb(6, 6, 84);
}

.semititle {
  order: 1;
  font-size: 32px;
  background-color: rgba(39, 103, 159, 0);
  border-radius: 30px;
  margin: 0%;
  padding: 3%;
  color: rgb(6, 6, 84);
}

.semicontainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 0px;
  order: 2;
  margin-top: 3%;
  padding: 1%;
}
.subheading{
  font-size: 110%;
}
.semi-card {
  background-color: rgba(51, 67, 80, 0);
  padding: 14pt;
  margin: 0%;
  border-radius: 0px;
 
  border: solid rgb(3, 3, 130);
}

.heading{
    font-size: 200%;
    margin: 2%;
}

.semicontainer :hover{
  background-color: gb(221, 231, 245);
   transform: scale(1.07); /* Zoom in by 10% */
    transition: transform 0.3s ease; 
    border: none;/* Smooth transition effect */
  
}



@media screen and (max-width: 800px) {
   .Semi {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  height: fit-content;
  margin: 5%;
}

 }