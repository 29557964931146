.footer {
  display: flex;
  flex-direction: row;
  background-color: rgba(51, 67, 80, 1);
  color: white;
  width: 100%;
  padding: 1%;
 
}

.engdiv{
  width: 50%;
}

.footer-container {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;

  align-items: end;
  justify-content: center;
  margin-left: 10%;
  margin-top: 10%;
  font-size: 110%;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  color: white;
  cursor: pointer;
}

.link{
  cursor: pointer;
}

.icon {
  margin-right: 10px;
}

.footer-item a {
  color: white; /* Change link color to white */
  text-decoration: none; 
  /* Remove underline */
}

.footer-item a:visited {
  color: white; /* Change visited link color to white */
}

@media screen and (max-width: 800px) {
  .footer-container {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .footer{
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .engdiv{
  width: 100%;
  }
}
